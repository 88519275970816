var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-wrap",
      attrs: {
        visible: _vm.previewVisible,
        width: "423px",
        "show-close": false,
        "show-header": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.previewVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "close-wrap" }, [
        _c("img", {
          attrs: { src: require("./img/close.png"), alt: "" },
          on: { click: _vm.closeHandler },
        }),
      ]),
      _c("div", { staticClass: "preview-container" }, [
        _c("div", { staticClass: "header-wrap" }, [
          _c("i", { staticClass: "el-icon-arrow-left" }),
          _c("div", { staticClass: "text" }, [_vm._v("常见问题")]),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.temp.title) + " "),
        ]),
        _c("div", {
          staticClass: "con",
          domProps: { innerHTML: _vm._s(_vm.temp.content) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }