<!--
 * @Author: faf
 * @desc: 
 * @Date: 2022-09-19 16:25:55
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <el-dialog
    :visible.sync="previewVisible"
    width="423px"
    :show-close="false"
    :show-header="false"
    class="dialog-wrap"
		:close-on-click-modal="false"
  >
    <div class="close-wrap">
      <img @click="closeHandler" src="./img/close.png" alt="" />
    </div>
    <div class="preview-container">
      <!-- <el-card class="preview-wrapper"> -->
      <div class="header-wrap">
        <i class="el-icon-arrow-left"></i>
        <div class="text">常见问题</div>
      </div>
      <div class="title">
        {{ temp.title }}
      </div>
      <!-- <div class="tip">
            <span class="type">资讯分类</span>
            <span>{{ this.$moment(new Date()).format("YYYY-MM-DD") }}</span>
          </div> -->
      <div class="con" v-html="temp.content" />
      <!-- </el-card> -->
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    closeValue: function () {
      return false;
    }
  },
  props: {
    previewVisible: {
      default: function () {
        return false;
      }
    },

    temp: {
      default: function () {
        return {};
      }
    }
  },
  methods: {
    closeHandler() {
      this.$emit("closeValue");
    }
  }
};
</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.dialog-wrap {
  .preview-container {
    margin: auto;
    padding: 0 20px;

    .title {
      font-size: 20px;
      color: #001A42;
      line-height: 28px;
      padding-bottom: 20px;
    }

    .con {
      color: #4F6689;
      line-height: 20px;

      >>>div, >>>span, >>>h1, >>>h2, >>>h3, >>>h4, >>>h5, >>>h6, >>>p, >>>blockquote, >>>pre, >>>a, >>>em, >>>font, >>>img, >>>small, >>>strike, >>>strong, >>>sub, >>>sup, >>>b, >>>u, >>>i, >>>center, >>>dl, >>>dt, >>>dd, >>>ol, >>>ul, >>>li, >>>fieldset, >>>form, >>>label, >>>legend, >>>table, >>>caption, >>>tbody, >>>tfoot, >>>thead, >>>tr, >>>th, >>>td {
        margin-bottom: 13px;
      }

      >>>p {
        display: inline-block;
        width: 100%;
        font-size: 16px;
      }

      >>>img {
        max-width: 100%;
        margin: 0;
      }

      >>>.ql-align-center {
        text-align: center;
      }

      >>>.ql-align-right {
        text-align: right;
      }
    }

    .tip {
      color: #999;
      font-size: 12px;
      margin: 4px 0 15px 0;

      .type {
        margin-right: 5px;
      }
    }
  }

  .header-wrap {
    display: flex;
    padding-bottom: 20px;

    >>>.el-icon-arrow-left:before {
      font-size: 24px;
      color: #001A42;
      line-height: 60px;
    }

    .text {
      width: 100%;
      padding: 20px 0 15px;
      text-align: center;
      font-size: 18px;
      color: #001A42;
    }
  }

  >>>.el-dialog__header {
    padding: 0;
  }

  .close-wrap {
    margin-top: -14px;
    padding-bottom: 24px;
    text-align: right;
    width: 100%;

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}
</style>
